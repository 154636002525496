import { PaymentMethod } from '../../../services/administration/payment-method.enum.ts';

const paymentMethodMessages: { [key in PaymentMethod]: string } = {
  0: 'Op rekening',
  1: 'Automatische incasso',
  2: 'Contant',
};

export const InvoiceMessages = {
  title: 'Factuur',
  description: 'Hier vind je al jouw facturen. Klik op een factuur om de details te bekijken.',
  display: 'Factuur {InvoiceNumber}',
  search: 'Zoek op factuurnummer of beschrijving',

  properties: {
    date: 'Factuurdatum',
    description: 'Omschrijving',
    number: 'Factuurnummer',
    paymentMethod: 'Betaalmethode',
  },

  downloadLineItems: 'Download factuurregels',
  line: {
    properties: {
      amount: 'Aantal',
      description: 'Omschrijving',
      ppu: 'Prijs per stuk',
      taxRate: 'BTW-tarief',
    },
  },
  lines: 'Factuurregels',
  paymentMethods: paymentMethodMessages,
};
